import React from "react";
import { Button } from "reactstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import * as styles from "./MainSection.module.scss";
import check from "./../../assets/img/check.svg";
import { useTranslation } from "react-i18next";

import { useParams } from "react-router-dom";

function MainSection() {
    let { country } = useParams();
    console.log(country, typeof country);
    const { t } = useTranslation();

    return (
        <div className={styles.main_wrapper_p}>
            {/* {country} */}
            <div className={`container`}>
                <div className={["row", styles.res_fix].join(" ")}>
                    <div className={["col-md-6", styles.text_container].join(" ")}>
                        <div className={styles.text_content}>
                            <h1>{t("partnersProgram")}</h1>
                            <p>{t("supportMerchants")}</p>

                            <div className={["row", styles.row].join(" ")}>
                                <div className={["col-md-6 col-sm-6", styles.btn_partners, styles.btn_partners_regions].join(" ")}>
                                    <Button disabled={false} color="primary" className={"mr-1 mb-1 btn-block"}>
                                        {t("regions")}
                                    </Button>
                                </div>
                                <div className={["col-md-6 col-sm-6", styles.btn_partners, styles.btn_partners_company].join(" ")}>
                                    <Button disabled={false} color="primary" className={"mr-1 mb-1 btn-block"}>
                                        {t("company")}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"col-md-6 " + styles.formfix}></div>
                </div>

                <div className={["row", styles.regions_container].join(" ")}>
                    <div className={["col-md-6", styles.text_container].join(" ")}>
                        <div className={styles.text_content}>
                            <h1>{t("regionsTerritories")}</h1>
                            <p>{t("supportMerchantsRegions")}</p>

                            <div className={[styles.regions_points].join(" ")}>
                                <div>
                                    <div style={{ display: "flex" }}>
                                        <div style={{ marginRight: "7px" }}>
                                            <LazyLoadImage alt={""} effect="blur" src={check} width="17px" />
                                        </div>

                                        <p>{t("digitalTerritory")}</p>
                                    </div>
                                </div>

                                <div style={{ marginBottom: "30px" }}>
                                    <div style={{ display: "flex" }}>
                                        <div style={{ marginRight: "7px" }}>
                                            <LazyLoadImage alt={""} effect="blur" src={check} width="17px" />
                                        </div>

                                        <p>{t("cityAnimation")}</p>
                                    </div>
                                </div>

                                <Button disabled={false} color="primary" className={"mr-1 mb-1 btn-block " + styles.buttonenv}>
                                    {t("calledBack")}
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className={["col-md-6", styles.regions_img].join(" ")}></div>
                </div>
            </div>
        </div>
    );
}

export default MainSection;
