import React from 'react'
import * as styles from './NavBar.module.scss'
import logo from './../../assets/img/logo-fideclub.svg'
import fr from './../../assets/img/french.png'
import en from './../../assets/img/EN.png'
import es from './../../assets/img/ES.png'


import {useLocation} from 'react-router-dom';

import { useTranslation } from 'react-i18next'
import { Link } from 'react-scroll'

function Logo () {
  return (
    <div>
      <img width="104px" height="41px" alt='logo' src={logo} />
    </div>
  )
}
function FR () {
  return (
    <div>
      <img width="23px" height="14px" alt='french' src={fr} />
    </div>
  )
}
function EN () {
  return (
    <div>
      <img width="23px" height="14px" alt='french' src={en} />
    </div>
  )
}
function ES () {
  return (
    <div>
      <img width="23px" height="14px" alt='french' src={es} />
    </div>
  )
}
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Lang (props) {
  switch (props.lang) {
    case 'fr':
      return <FR />
      break;
    case 'es':
      return <ES />
      break
    default:
      return <EN />
      break
  }
}

function Menu () {
  const { t, i18n } = useTranslation()
  const [lang, setLang] = React.useState(i18n.language)
  
  let query = useQuery();


  const changeLanguage = lng => {
    setLang(lng)
    i18n.changeLanguage(lng)
  }

  React.useEffect(() => {
    console.log('use eff', lang)
    if (query.get("hreflang")) {
        if (String(query.get("hreflang")).trim() == 'es' ||
        String(query.get("hreflang")).trim() == 'en' ||
        String(query.get("hreflang")).trim() == 'fr') {
          changeLanguage(query.get("hreflang"))
        }
    }
  }, [lang])


  return (
    <div className={styles.main_wrapper}>
      <div className={styles.inner_pad}>
        <nav className='navbar navbar-expand-lg navbar-expand-lg navbar-light '>
          <div className={styles.fix_responsive_logo}>
            {' '}
            <Logo className={styles.fix_responsive_logo} />
          </div>
          <button
            className='navbar-toggler'
            type='button'
            data-toggle='collapse'
            data-target='#navbarSupportedContent'
            aria-controls='navbarSupportedContent'
            aria-expanded='true'
            aria-label='Toggle navigation'
          >
            <span className='navbar-toggler-icon'></span>
          </button>

          <div className='collapse navbar-collapse' id='navbarSupportedContent'>
            <div
              className={
                'form-inline my-2 mr-auto ' + styles.fix_responsive_logo_hide
              }
            >
              <Logo />
            </div>

            <ul className={'navbar-nav  ' + styles.navbar_fix}>
              <li className='nav-item active'>
                <Link activeClass='active' to='home' spy={true} smooth={true}>
                  {t('Your business')}
                </Link>
                {/* <a className='nav-link' href='#'> */}
                {/* Votre commerce */}
                {/* {t('Your business')} */}
                {/* </a> */}
              </li>
              <li className='nav-item'>
                <Link to='how_works' spy={true} smooth={true}>
                  {t('How it works')}
                </Link>
                {/* <a className='nav-link' href='#'> */}
                {/* Comment ça marche */}
                {/* {t('How it works')} */}
                {/* </a> */}
              </li>
              <li className='nav-item'>
                <Link to='prices' spy={true} smooth={true}>
                  {' '}
                  {t('Prices')}
                </Link>
                {/* <a className='nav-link' href='#'> */}
                {/* Tarifs */}
                {/* {t('Prices')} */}
                {/* </a> */}
              </li>
              {/* <li className='nav-item'>
                <a className='nav-link' href='#'>
                  {t('Blog')}
                </a>
              </li> */}
              <li className={`nav-item ` + styles.custom_btn}>
                <a
                  className='nav-link'
                  href='https://fideclub.com/home/vendor_logup/registration'
                  target='_blank'
                >
                  {/* Je suis un particulier */}
                  {t('I am an individual')}
                </a>
              </li>
              <li className={`nav-item dropdown ` + styles.round_language}>
                <a
                  className={'nav-link dropdown-toggle ' + styles.custom_arrow}
                  href='#'
                  id={styles.navbarDropdown}
                  role='button'
                  data-toggle='dropdown'
                  aria-haspopup='true'
                  aria-expanded='false'
                >
                  <Lang lang={lang} />
                </a>
                <div
                  className={'dropdown-menu ' + styles.dropdownmenu_custom}
                  aria-labelledby='navbarDropdown'
                >
                  <a
                    className='dropdown-item'
                    href='#'
                    onClick={() => changeLanguage('en')}
                  >
                    EN
                  </a>
                  <a
                    className='dropdown-item'
                    href='#'
                    onClick={() => changeLanguage('fr')}
                  >
                    FR
                  </a>
                  {/* <div className="dropdown-divider"></div> */}
                  <a
                    className='dropdown-item'
                    href='#'
                    onClick={() => changeLanguage('es')}
                  >
                    ES
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  )
}

const NavBar = () => {
  const [show, setShow] = React.useState(false)

  React.useEffect(() => {
    //   setTimeout(() => {
    setShow(true)
    //   }, 5000)
  }, [show])

  if (!show) return null

  return (
    <div className={`container-fluid`}>
      <Menu />
    </div>
  )
}
export default NavBar
